import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './screens/logIn';
import { loadStripe } from '@stripe/stripe-js';
import EmployeeSignup from './screens/employeeSignup';
import EmployeeVerification from './screens/employeeVerification';
import MasterAccountCreation from './screens/MasterAccountCreation';
import EmailVerification from './screens/EmailVerification';
import MasterAccountSetup from './screens/MasterAccountSetup';
import ForgotPassword from './screens/ForgotPassword';
import ActionPage from './screens/ActionPage';
import SuccessPage from './screens/Success';
import AdminAccountDetails from './screens/AdminAccount';
import EmployeeAccount from './screens/EmployeeAccount';


const stripePromise = loadStripe('pk_live_51NEDchBQRI88GTVxMlp7NefC69fO61iMpBAbJnmRld7kRMzuXqVrRdaoo0ngAwgHGFRoeHBiYFEZKt7YBJA0jdwW00CUghgQOr');

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/employeeSignup" element={<EmployeeSignup/>} />
          <Route path="/employeeVerification" element={<EmployeeVerification/>} />
          <Route path="/account-creation" element={<MasterAccountCreation/>} />
          <Route path="/email-verification" element={<EmailVerification/>} />
          <Route path="/master-account-setup" element={<MasterAccountSetup/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="action" element={<ActionPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/adminaccount" element={<AdminAccountDetails />} />
          <Route path="/employeeaccount" element={<EmployeeAccount />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;