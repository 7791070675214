import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { checkActionCode, applyActionCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../config/firebase';
import '../style/ActionPage.css';
import logo2 from '../assets/logo-wordless.png'; // Ensure you have the correct path to your logo

const ActionPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [inputsVisible, setInputsVisible] = useState(true);

    useEffect(() => {
        if (mode === 'verifyEmail' && oobCode) {
            // First, check the action code
            checkActionCode(auth, oobCode)
                .then(() => {
                    // If the action code is valid, apply it
                    applyActionCode(auth, oobCode)
                        .then(() => {
                            setMessage('Email verified successfully.');
                            setError(''); // Clear any previous errors if email is verified successfully
                        })
                        .catch(error => {
                            if (error.code === 'auth/email-already-in-use') {
                                setMessage('The email address is already verified.');
                                setError('');
                            } else if (error.code === 'auth/invalid-action-code') {
                                setError('The email verification link is invalid.\nPlease log in or request a new verification email.');
                            } else if (error.code === 'auth/expired-action-code') {
                                setError('The email verification link has expired.\nPlease log in or request a new verification email.');
                            } else {
                                setError('Error verifying email.\nPlease log in or request a new verification email.');
                            }
                        });
                })
                .catch(error => {
                    if (error.code === 'auth/invalid-action-code') {
                        setError('The email verification link is invalid.\nPlease log in or request a new verification email.');
                    } else if (error.code === 'auth/expired-action-code') {
                        setError('The email verification link has expired.\nPlease log in or request a new verification email.');
                    } else {
                        setError('Error verifying email.\nPlease log in or request a new verification email.');
                    }
                });
        }
    }, [mode, oobCode]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (newPassword.length < 6) {
            setError('Password should be at least 6 characters.');
            return;
        }

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            setMessage('Password has been reset successfully.');
            setInputsVisible(false);
        } catch (error) {
            console.error(error.message);
            setError('Error resetting password. Please try again.');
        }
    };

    if (mode === 'resetPassword') {
        return (
            <div className="reset-password-container">
                <div className="logo-container">
                    <img src={logo2} alt="Logo" className="logo" />
                </div>
                <div className="reset-password-content">
                    <h2>Reset Password</h2>
                    {message && (
                        <div className="success-message">
                            {message}
                            <button onClick={() => navigate('/login')}>Go to Login</button>
                        </div>
                    )}
                    {inputsVisible && (
                        <form onSubmit={handlePasswordReset}>
                            <div className="input-group">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    required
                                    placeholder="Enter new password"
                                />
                            </div>
                            <div className="input-group">
                                <label>Confirm New Password</label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    required
                                    placeholder="Confirm new password"
                                />
                            </div>
                            {error && <div className="error-message">{error.split('\n').map((line, index) => <div key={index}>{line}</div>)}</div>}
                            <button type="submit">Reset Password</button>
                        </form>
                    )}
                </div>
            </div>
        );
    } else if (mode === 'verifyEmail') {
        return (
            <div className="verify-email-container">
                <div className="logo-container">
                    <img src={logo2} alt="Logo" className="logo" />
                </div>
                <div className="verify-email-content">
                    {message && <div className="success-message">{message}</div>}
                    {error && !message && <div className="error-message">{error.split('\n').map((line, index) => <div key={index}>{line}</div>)}</div>}
                    {message && (
                        <button onClick={() => navigate('/login')}>Go to Login</button>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="action-page-container">
                <div className="logo-container">
                    <img src={logo2} alt="Logo" className="logo" />
                </div>
                <div className="action-page-content">
                    <h2>Invalid Action</h2>
                    <p>The action you are trying to perform is not valid.</p>
                </div>
            </div>
        );
    }
};

export default ActionPage;
