import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, functions } from '../config/firebase';
import { collection, getDocs, doc, getDoc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useTable } from 'react-table';
import '../style/AdminAccount.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo2 from '../assets/logo-wordless.png';
import Modal from '../components/Modal';

const AdminAccount = () => {
  const [employees, setEmployees] = useState([]);
  const [adminInfo, setAdminInfo] = useState({});
  const [clinicInfo, setClinicInfo] = useState({});
  const [showEmployeeManagement, setShowEmployeeManagement] = useState(false);
  const [showAddEmployeeForm, setShowAddEmployeeForm] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [newEmployee, setNewEmployee] = useState({
    isActive: true,
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    admin: false,
    isProvider: false,
  });
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [originalDetails, setOriginalDetails] = useState({});
  const navigate = useNavigate();

  const fetchEmployees = useCallback(async (clinicId) => {
    const employeesRef = collection(db, `clinics/${clinicId}/employees`);
    const employeesSnapshot = await getDocs(employeesRef);
    setEmployees(employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  }, []);

  const fetchClinicInfo = useCallback(async (clinicId) => {
    const clinicDoc = await getDoc(doc(db, "clinics", clinicId));
    if (clinicDoc.exists()) {
      setClinicInfo(clinicDoc.data());
    }
  }, []);

  const fetchAdminInfo = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();
      setAdminInfo(userData);
      fetchEmployees(userData.clinicId);
      fetchClinicInfo(userData.clinicId);
      setOriginalDetails({ ...userData, ...clinicInfo });
    } else {
      setShowLoginModal(true);
    }
  }, [fetchClinicInfo, fetchEmployees]);

  useEffect(() => {
    fetchAdminInfo();
  }, [fetchAdminInfo, isLoggedIn]);

  const handleClinicInputChange = (e) => {
    const { name, value } = e.target;
    setClinicInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    setIsEditingDetails(true);
  };
  
  const handleAdminInputChange = (e) => {
    const { name, value } = e.target;
    setAdminInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    setIsEditingDetails(true);
  };

  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value === "10" ? "9+" : value;
    setClinicInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    setIsEditingDetails(true);
  };

  const handleSliderDisplay = (value) => {
    return value === "10" ? "9+" : value;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleNewEmployeeInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewEmployee(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleAddEmployee = async () => {
    if (!newEmployee.firstName || !newEmployee.lastName || !newEmployee.role || !newEmployee.email) {
      setValidationError('All fields are required.');
      return;
    }

    if (!validateEmail(newEmployee.email)) {
      setValidationError('Invalid email format.');
      return;
    }

    try {
      let employeeDocRef;
      if (editingEmployee) {
        employeeDocRef = doc(db, `clinics/${adminInfo.clinicId}/employees`, editingEmployee.id);
        await updateDoc(employeeDocRef, newEmployee);
      } else {
        employeeDocRef = await addDoc(collection(db, `clinics/${adminInfo.clinicId}/employees`), newEmployee);
        // Send invitation email to new employee
        const sendInvitationEmail = httpsCallable(functions, 'sendInvitationEmail');
        await sendInvitationEmail({
          email: newEmployee.email,
          employeeId: employeeDocRef.id,
          firstName: newEmployee.firstName,
          lastName: newEmployee.lastName,
          role: newEmployee.role,
          clinicId: adminInfo.clinicId,
          clinicName: clinicInfo.clinicName,
          adminStatus: newEmployee.admin,
          signUpLink: `https://app.vetreum.com/employeeSignup?clinicId=${adminInfo.clinicId}&email=${newEmployee.email}&firstName=${newEmployee.firstName}&lastName=${newEmployee.lastName}&role=${newEmployee.role}&clinicName=${clinicInfo.clinicName}`
        });
      }

      setNewEmployee({
        isActive: true,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        admin: false,
        isProvider: false,
      });
      setShowAddEmployeeForm(false);
      setEditingEmployee(null);
      fetchEmployees(adminInfo.clinicId); // Refresh employee list
      setValidationError(''); // Clear validation error
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };

  const handleEditEmployee = (employee) => {
    setNewEmployee(employee);
    setEditingEmployee(employee);
    setShowAddEmployeeForm(true);
  };

  const handleDeleteEmployee = async () => {
    try {
      const employeeDocRef = doc(db, `clinics/${adminInfo.clinicId}/employees`, employeeToDelete.id);
      await deleteDoc(employeeDocRef);
      setEmployeeToDelete(null);
      setShowDeleteModal(false);
      setShowAddEmployeeForm(false);
      fetchEmployees(adminInfo.clinicId); // Refresh employee list
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleManagePayments = async () => {
    if (clinicInfo.stripeCustomerId) {
      const createPortalSession = httpsCallable(functions, 'createStripePortalSession');
      try {
        const result = await createPortalSession({ customerId: clinicInfo.stripeCustomerId });
        const { url } = result.data;
        window.location.href = url;
      } catch (error) {
        console.error("Error creating Stripe portal session:", error);
      }
    } else {
      console.error("Stripe Customer ID not available");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      const user = userCredential.user;

      if (!user.emailVerified) {
        navigate('/email-verification');
        return;
      }

      // Fetch user data from Firestore to check admin status and profile completion
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Store user information in local storage
        localStorage.setItem('user', JSON.stringify(user));

        if (userData.admin) {
          if (!userData.isProfileComplete) {
            navigate('/master-account-setup');
          } else {
            setShowLoginModal(false);
            setIsLoggedIn(true); // Trigger re-fetch and re-render
          }
        } else {
          navigate('/employeeaccount'); // Redirect non-admin users (employees) to homepage
        }
      } else {
        console.error('No user data available');
        setLoginError('No user data available.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('Failed to login. Please check your credentials and try again.');
    }
  };

  const saveChanges = async () => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const clinicDocRef = doc(db, "clinics", adminInfo.clinicId);

      await updateDoc(userDocRef, adminInfo);
      await updateDoc(clinicDocRef, clinicInfo);

      setOriginalDetails({ ...adminInfo, ...clinicInfo });
      setIsEditingDetails(false);
      alert('Changes saved successfully.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes.');
    }
  };

  const cancelChanges = () => {
    setAdminInfo(originalDetails);
    setClinicInfo(originalDetails);
    setIsEditingDetails(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: row => `${row.firstName} ${row.lastName}`, // Combine first and last name
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Edit',
        Cell: ({ row }) => (
          <div>
            <button className="edit-button" onClick={() => handleEditEmployee(row.original)}>
              <i className="fas fa-edit"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: employees });

  return (
    <div className="admin-account-container">
  <div className="logo-container">
    <img src={logo2} alt="Logo" className="logo" />
  </div>
  <div className={`admin-account-content ${isEditingDetails ? 'expanded' : ''}`}>
    <h2>User Settings</h2>

    <section className={`details-container ${isEditingDetails ? 'expanded' : ''}`}>
      <h3>Details</h3>
      <div className="details-grid">
        <label>
          Clinic Name:
          <input
            type="text"
            name="clinicName"
            value={clinicInfo.clinicName || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={clinicInfo.address || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          City:
          <input
            type="text"
            name="city"
            value={clinicInfo.city || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          State:
          <input
            type="text"
            name="state"
            value={clinicInfo.state || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          Zip Code:
          <input
            type="text"
            name="zipCode"
            value={clinicInfo.zipCode || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          Primary Contact:
          <input
            type="text"
            name="primaryContactName"
            value={clinicInfo.primaryContactName || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          Clinic Email:
          <input
            type="email"
            name="email"
            value={clinicInfo.email || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label>
          Phone Number:
          <input
            type="text"
            name="phoneNumber"
            value={clinicInfo.phoneNumber || ''}
            onChange={handleClinicInputChange}
          />
        </label>
        <label className="slider-label">
          Number of Doctors:<span>{handleSliderDisplay(clinicInfo.numberOfDoctors)}</span>
          <input
            type="range"
            name="numberOfDoctors"
            min="1"
            max="9"
            value={clinicInfo.numberOfDoctors || ''}
            onChange={handleSliderChange}
          />
        </label>
        <label>
          User First Name:
          <input
            type="text"
            name="firstName"
            value={adminInfo.firstName || ''}
            onChange={handleAdminInputChange}
          />
        </label>
        <label>
          User Last Name:
          <input
            type="text"
            name="lastName"
            value={adminInfo.lastName || ''}
            onChange={handleAdminInputChange}
          />
        </label>
        <label>
          User Email:
          <input
            type="email"
            name="email"
            value={adminInfo.email || ''}
            onChange={handleAdminInputChange}
          />
        </label>
      </div>
      <div className={`edit-buttons ${isEditingDetails ? 'visible' : ''}`}>
        <button className="cancel-button" onClick={cancelChanges}>
          <i className="fas fa-times"></i>
        </button>
        <button className="save-button" onClick={saveChanges}>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </section>
    <div className="button-container">
      <button className="manage-employees-button" onClick={() => setShowEmployeeManagement(!showEmployeeManagement)}>
        {showEmployeeManagement ? "Hide Employee Management" : "Manage Employees"}
      </button>
      <button className="manage-payments-button" onClick={handleManagePayments}>Manage Payments</button>
    </div>

    {showEmployeeManagement && (
      <section className="employee-management">
        <button className="add-employee-button" onClick={() => { setEditingEmployee(null); setNewEmployee({ isActive: true, firstName: '', lastName: '', email: '', role: '', admin: false, isProvider: false }); setShowAddEmployeeForm(true); }}>
          <i className="fas fa-plus"></i>
        </button>
        <h3>Current Employees</h3>
        <table {...tableInstance.getTableProps()} className="employees-table">
          <thead>
            {tableInstance.headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...tableInstance.getTableBodyProps()}>
            {tableInstance.rows.map(row => {
              tableInstance.prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    )}

    <p className="sign-out-text" onClick={handleSignOut}>Sign Out</p>
  </div>

  <Modal isOpen={showAddEmployeeForm} onClose={() => setShowAddEmployeeForm(false)}>
    <h3>{editingEmployee ? "Edit Employee" : "Add New Employee"}</h3>
    {validationError && <div className="error-message">{validationError}</div>}
    <label>
      Is Active:
      <input type="checkbox" name="isActive" checked={newEmployee.isActive} onChange={handleNewEmployeeInputChange} />
    </label>
    <label>
      First Name:
      <input type="text" name="firstName" value={newEmployee.firstName} onChange={handleNewEmployeeInputChange} />
    </label>
    <label>
      Last Name:
      <input type="text" name="lastName" value={newEmployee.lastName} onChange={handleNewEmployeeInputChange} />
    </label>
    <label>
      Email:
      <input type="email" name="email" value={newEmployee.email} onChange={handleNewEmployeeInputChange} />
    </label>
    <label>
      Role:
      <input type="text" name="role" value={newEmployee.role} onChange={handleNewEmployeeInputChange} placeholder="dvm, tech, assistant, reception, etc." />
    </label>
    <label>
      Admin:
      <input type="checkbox" name="admin" checked={newEmployee.admin} onChange={handleNewEmployeeInputChange} />
    </label>
    <label>
      Is Provider:
      <input type="checkbox" name="isProvider" checked={newEmployee.isProvider} onChange={handleNewEmployeeInputChange} />
    </label>
    <button onClick={handleAddEmployee}>{editingEmployee ? "Update" : "Save"}</button>
    {editingEmployee && (
      <button className="delete-button" onClick={() => { setShowDeleteModal(true); setEmployeeToDelete(editingEmployee); }}>
        Delete User
      </button>
    )}
  </Modal>

  <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
    <h3>Are you sure?</h3>
    <p>This is permanent and will delete user data and all access.</p>
    <button onClick={handleDeleteEmployee}>Yes</button>
    <button onClick={() => setShowDeleteModal(false)}>No</button>
  </Modal>

  <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
    <h3>Login</h3>
    <form onSubmit={handleLogin}>
      <div className="input-group">
        <label>Email</label>
        <input type="email" value={loginEmail} onChange={e => setLoginEmail(e.target.value)} required placeholder="Enter email" />
      </div>
      <div className="input-group">
        <label>Password</label>
        <input type="password" value={loginPassword} onChange={e => setLoginPassword(e.target.value)} required placeholder="Enter password" />
      </div>
      {loginError && <div className="error-message">{loginError}</div>}
      <button type="submit">Log In</button>
    </form>
  </Modal>
</div>
  );
};

export default AdminAccount;
