import React from 'react';
import '../style/EmployeeVerification.css';

const EmailVerification = () => {
  return (
    <div className="container">
      <div className="master-account-creation">
        <div className="email-verification">
          <h2>Email Verification</h2>
          <p>Please check your email for a verification link. Click the link to complete the verification process.</p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;