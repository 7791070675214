import React, { useState } from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import '../style/EmailVerification.css';

const EmailVerification = () => {
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  const handleResendEmail = () => {
    setIsResendingEmail(true);

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      sendEmailVerification(currentUser)
        .then(() => {
          // Email sent successfully
          setIsResendingEmail(false);
          alert('Verification email sent. Please check your inbox.');
        })
        .catch((error) => {
          // Failed to send email
          setIsResendingEmail(false);
          alert('Failed to send verification email. Please try again.');
        });
    } else {
      // User is not logged in
      setIsResendingEmail(false);
      alert('Please sign in to resend the verification email.');
    }
  };

  return (
    <div className="container">
      <div className="verification">
        <h2>Email Verification</h2>
        <p>
          Thank you for creating your account. To complete the sign-up process,
          please check your email and click on the verification link.
        </p>
        <button onClick={handleResendEmail} disabled={isResendingEmail}>
          {isResendingEmail ? 'Sending...' : 'Resend Verification Email'}
        </button>
      </div>
    </div>
  );
};

export default EmailVerification;