import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA4sWzHmGTNM1iYcAmeyTqfHmAz8-Mzt_o",
  authDomain: "vetreum-de078.firebaseapp.com",
  projectId: "vetreum-de078",
  storageBucket: "vetreum-de078.appspot.com",
  messagingSenderId: "46223946711",
  appId: "1:46223946711:web:679b142aeb8500c5d7d192",
  measurementId: "G-X5D1E81H9V"
};

const app = initializeApp(firebaseConfig);

// Analytics
const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;

// Auth
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Set authentication persistence
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting auth persistence:", error);
});

// Firestore
const db = getFirestore(app);

// Functions
const functions = getFunctions(app);

export { app, analytics, auth, googleProvider, db, functions };
