import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import '../style/EmployeeAccount.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo2 from '../assets/logo-wordless.png';
import Modal from '../components/Modal';

const EmployeeAccount = () => {
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [clinicInfo, setClinicInfo] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalEmployeeInfo, setOriginalEmployeeInfo] = useState({});
  const navigate = useNavigate();

  const fetchEmployeeInfo = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();
      setEmployeeInfo(userData);
      setOriginalEmployeeInfo(userData);
      fetchClinicInfo(userData.clinicId);
    } else {
      setShowLoginModal(true);
    }
  };

  const fetchClinicInfo = async (clinicId) => {
    const clinicDoc = await getDoc(doc(db, "clinics", clinicId));
    if (clinicDoc.exists()) {
      setClinicInfo(clinicDoc.data());
    }
  };

  useEffect(() => {
    fetchEmployeeInfo();
  }, [isLoggedIn]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      const user = userCredential.user;

      if (!user.emailVerified) {
        navigate('/email-verification');
        return;
      }

      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        localStorage.setItem('user', JSON.stringify(user));

        if (!userData.isProfileComplete) {
          navigate('/master-account-setup');
        } else {
          setShowLoginModal(false);
          setIsLoggedIn(true);
        }
      } else {
        console.error('No user data available');
        setLoginError('No user data available.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('Failed to login. Please check your credentials and try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeInfo({
      ...employeeInfo,
      [name]: value,
    });
    setIsEditing(true);
  };

  const saveChanges = async () => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, employeeInfo);
      setOriginalEmployeeInfo(employeeInfo);
      setIsEditing(false);
      alert('Changes saved successfully.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes.');
    }
  };

  const cancelChanges = () => {
    setEmployeeInfo(originalEmployeeInfo);
    setIsEditing(false);
  };

  return (
    <div className="admin-account-container">
      <div className="logo-container">
        <img src={logo2} alt="Logo" className="logo" />
      </div>
      <div className={`admin-account-content ${isEditing ? 'expanded' : ''}`}>
        <h2>Employee Dashboard</h2>
        <p>Clinic: {clinicInfo.clinicName}</p>
        <section>
          <h3>Employee Details</h3>
          <div className={`user-details ${isEditing ? 'editing' : ''}`}>
            <div className="input-row">
              <label>
                First Name:
                <input
                  type="text"
                  name="firstName"
                  value={employeeInfo.firstName || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  value={employeeInfo.lastName || ''}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className="input-row">
              <label>
                Role:
                <input
                  type="text"
                  name="role"
                  value={employeeInfo.role || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={employeeInfo.email || ''}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className={`edit-buttons ${isEditing ? 'visible' : ''}`}>
              <button className="cancel-button" onClick={cancelChanges}>
                <i className="fas fa-times"></i>
              </button>
              <button className="save-button" onClick={saveChanges}>
                <i className="fas fa-check"></i>
              </button>
            </div>
          </div>
        </section>
        <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button>
      </div>

      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <h3>Login</h3>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Email</label>
            <input type="email" value={loginEmail} onChange={e => setLoginEmail(e.target.value)} required placeholder="Enter email" />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input type="password" value={loginPassword} onChange={e => setLoginPassword(e.target.value)} required placeholder="Enter password" />
          </div>
          {loginError && <div className="error-message">{loginError}</div>}
          <button type="submit">Log In</button>
        </form>
      </Modal>
    </div>
  );
};

export default EmployeeAccount;