import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase';
import '../style/ForgotPassword.css';
import logo2 from '../assets/logo-wordless.png'; // Ensure you have the correct path to your logo

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your inbox.');
        } catch (error) {
            setError('Failed to send password reset email. Please check your email address and try again.');
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="logo-container">
                <img src={logo2} alt="Logo" className="logo" />
            </div>
            <div className="forgot-password-content">
                <h2>Reset Password</h2>
                <form onSubmit={handlePasswordReset}>
                    <div className="input-group">
                        <label>Email</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={e => setEmail(e.target.value)} 
                            required 
                            placeholder="Enter email" 
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {message && <div className="success-message">{message}</div>}
                    <button type="submit">Send Reset Email</button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
