import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import '../style/Login.css';
import logo2 from '../assets/logo-wordless.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        navigate('/email-verification');
        return;
      }

      // Fetch user data from Firestore to check admin status and profile completion
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.admin) {
          if (!userData.isProfileComplete) {
            navigate('/master-account-setup');
          } else {
            navigate('/adminaccount'); // Redirect admin to admin account details
          }
        } else {
          navigate('/homepage'); // Redirect non-admin users (employees) to homepage
        }
      } else {
        console.error('No user data available');
        setError('No user data available.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Failed to login. Please check your credentials and try again.');
    }
  };

  return (
    <div className="master-account-creation-container">
      <div className="logo-container">
        <img src={logo2} alt="Logo" className="logo" />
      </div>
      <div className="master-account-creation-content">
        <h2>Welcome Back</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Email</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} required placeholder="Enter email" />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} required placeholder="Enter password" />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit">Log In</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
