// SuccessPage.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userId = query.get('userId');

    if (userId) {
      // Fetch the Stripe customer ID from the URL or session storage (if saved during checkout)
      const stripeCustomerId = sessionStorage.getItem('stripeCustomerId');

      // Save the Stripe customer ID to Firestore
      const saveCustomerIdToFirestore = async () => {
        try {
          const userDocRef = doc(db, 'users', userId);
          await updateDoc(userDocRef, {
            stripeCustomerId,
          });
          navigate('/dashboard'); // Navigate to the desired page after saving
        } catch (error) {
          console.error('Error saving Stripe customer ID:', error);
          alert('An error occurred while processing your subscription. Please contact support.');
        }
      };

      if (stripeCustomerId) {
        saveCustomerIdToFirestore();
      } else {
        alert('Missing Stripe customer ID. Please contact support.');
      }
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  return <div>Processing your subscription...</div>;
};

export default SuccessPage;
