import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import PasswordChecklist from "react-password-checklist";
import '../style/EmployeeSignup.css';
import logo2 from '../assets/logo-wordless.png';

const EmployeeSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [role, setRole] = useState('');
  const [adminStatus, setAdminStatus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clinicIdFromURL = queryParams.get('clinicId');
    const clinicNameFromURL = queryParams.get('clinicName');
    const emailFromURL = queryParams.get('email');
    const firstNameFromURL = queryParams.get('firstName');
    const lastNameFromURL = queryParams.get('lastName');
    const roleFromURL = queryParams.get('role');
    const adminFromURL = queryParams.get('admin');
    
    if (clinicIdFromURL) setClinicId(clinicIdFromURL);
    if (clinicNameFromURL) setClinicName(clinicNameFromURL);
    if (emailFromURL) setEmail(emailFromURL);
    if (firstNameFromURL) setFirstName(firstNameFromURL);
    if (lastNameFromURL) setLastName(lastNameFromURL);
    if (roleFromURL) setRole(roleFromURL);
    if (adminFromURL) setAdminStatus(adminFromURL === 'true');
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    if (!passwordValid) {
      alert("Password does not meet the requirements");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const employeeId = user.uid; // Assuming employee ID is the same as user ID

      // Send email verification
      await sendEmailVerification(user);

      // Save employee information to Firestore (users collection)
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email,
        clinicId,
        clinicName,
        admin: adminStatus,
        isProfileComplete: true,
        role,
        employeeId, 
        isActive: true // Assuming the employee is active upon creation
      });

      // Save employee information to Firestore (clinic's employees subcollection)
      const employeeDocRef = doc(db, `clinics/${clinicId}/employees`, employeeId);
      await setDoc(employeeDocRef, {
        firstName,
        lastName,
        email,
        role,
        employeeId,
        isActive: true,
        clinicId,
        clinicName,
        admin: adminStatus
      });

      // Define the quick picks to auto-generate
      const quickPicks = [
        { label: 'Surgery History', question: 'List and summarize this patient’s entire surgery history.' },
        { label: 'Recent Bloodwork', question: 'What were the results from the most recent bloodwork this patient had done?' },
        // Add more quick picks as needed
      ];

      // Save the quick picks in Firestore
      const quickPicksCollectionRef = collection(db, `users/${user.uid}/quickpicks`);
      for (const quickPick of quickPicks) {
        try {
          await addDoc(quickPicksCollectionRef, quickPick);
        } catch (error) {
          console.error("Error saving quick pick:", error);
        }
      }

      // Redirect to employee or admin dashboard based on admin status
       navigate('/email-verification'); // Navigate to admin account page
    } catch (error) {
      console.error("Error during employee sign up:", error.message);
      alert("An error occurred during sign up. Please try again later.");
    }
  };

  return (
    <div className="master-account-creation-container">
      <div className="logo-container">
        <img src={logo2} alt="Logo" className="logo" />
      </div>
      <div className="master-account-creation-content">
        <h2>Employee Sign Up</h2>
        <p>Clinic: {clinicName}</p>
        <p>Login: {email}</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="Enter first name"
            />
          </div>
          <div className="input-group">
            <label>Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="Enter last name"
            />
          </div>
          <div className="input-group">
            <label>Role</label>
            <input
              type="text"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              placeholder="Enter role (DVM, CVT, assistant, reception, etc.)"
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter password"
            />
          </div>
          <div className="input-group">
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm password"
            />
          </div>
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={12}
            value={password}
            valueAgain={confirmPassword}
            onChange={(isValid) => setPasswordValid(isValid)}
          />
          <button type="submit">Sign Up</button>
        </form>
      </div>
    </div>
  );
};

export default EmployeeSignup;
